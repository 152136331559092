

























































import { Component, Vue } from "vue-property-decorator";
import { IHandlerUpdate } from "@/interfaces";
import { dispatchGetHandler, dispatchUpdateHandler } from "@/store/main/actions";
import { readHandler } from '@/store/main/getters';

@Component
export default class Edit extends Vue {
  public valid = false;
  public loading = true;

  public handler: IHandlerUpdate = {
    name: "",
    type: "",
    params_1: "",
  };

  public reset() {
    this.handler = <IHandlerUpdate>{};
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }
  
  get handlerDetail(){
    return readHandler(this.$store);
  }

  public async mounted() {
    await dispatchGetHandler(this.$store, Number(this.$router.currentRoute.params.id));
    this.handler = <IHandlerUpdate>{ ...readHandler(this.$store) };
    this.loading = false;
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      await dispatchUpdateHandler(this.$store, {handlerId: Number(this.$router.currentRoute.params.id), data: this.handler});
      this.$router.back();
    }
  }
}
